import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { CopyTariffProposalItemCriteria } from "domain/entity/StandardProposal/CopyTariffProposalItemCriteria";
import { SaveTariffProposalItemCriteria } from "domain/entity/StandardProposal/SaveTariffProposalItemCriteria";
import { StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { StandardProposalItemEntity } from "domain/entity/StandardProposal/StandardProposalItemEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { StandardProposalItemRepository } from "./StandardProposalItemRepo";

export const StandardProposalItemRepoImpl = (): StandardProposalItemRepository => {

    const searchUrl = "/v1/standardProposalItemSearch"
    const saveUrl = "/v1/saveStandardProposalItem"
    const saveNewUrl = "/v1/saveNewStandardProposal"
    const copyItemUrl = "/v1/copyStdProposalTariffItems"
    const generaterStdItemUrl = "/v1/generateStdProposalSubTariffCode"
    const searchStandardProposalItem = async (entity: StandardProposalEntity): Promise<StandardProposalItemEntity[]> => {

        return axiosPostData(tariffAxiosInstance, `${searchUrl}`, entity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const saveTariffItem = async (criteria: SaveTariffProposalItemCriteria): Promise<ResponseEntity> => {

        try {
            return await axiosPostData(tariffAxiosInstance, `${saveUrl}`, criteria) as ResponseEntity;
        } catch (err) {
            throw err;
        }
    }

    const saveNewStandardProposal = async (criteria: SaveTariffProposalItemCriteria): Promise<ResponseEntity> => {

        try {
            return await axiosPostData(tariffAxiosInstance, `${saveNewUrl}`, criteria) as ResponseEntity;
        } catch (err) {
            throw err;
        }
    }

    const copyStdProposalTariffItems = async (criteria: CopyTariffProposalItemCriteria): Promise<ResponseEntity> => {

        try {
            return await axiosPostData(tariffAxiosInstance, `${copyItemUrl}`, criteria) as ResponseEntity;
        } catch (err) {
            throw err;
        }
    }

    const generateStdProposalSubTariffCode = async (criteria: SaveTariffProposalItemCriteria): Promise<ResponseEntity> => {
        return axiosPostData(tariffAxiosInstance, `${generaterStdItemUrl}`, criteria).then(res => {
            if (res.msg === "OK") {
                return res.data;
            } else {
                let errorMessage = "Error when generate sub tariff code."
                if (res.data && typeof (res.data) === "string") {
                    errorMessage = res.data;
                }
                return "Error:" + errorMessage;
            }
        }).catch(err => {
            return "Error:" + err.message;
        });
    }

    return {
        searchStandardProposalItem: searchStandardProposalItem,
        saveTariffItem: saveTariffItem,
        saveNewStandardProposal: saveNewStandardProposal,
        copyStdProposalTariffItems: copyStdProposalTariffItems,
        generateStdProposalSubTariffCode: generateStdProposalSubTariffCode,
    }
}
