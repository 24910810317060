import { surChargeTypeDropdownOption, uomDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { StandardProposalConstant, standardProposalIsRequiredFieldList, standardProposalTierRequiredFieldList } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { memo, useCallback, useMemo } from "react";
import { FieldType, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";


const TariffItemIsEditPanel = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const { currentTariffItem, currentTariffItemTier, currentEditTariffItemTier, currentTariffItemIs, currentEditTariffItemIs, isAddIs, isAddTier, isEditIs, isEditTier } = standardProposalState;
    const currentTier = (isEditTier || isAddTier) ? currentEditTariffItemTier : currentTariffItemTier;
    const currentIs = (isEditIs || isAddIs) ? currentEditTariffItemIs : currentTariffItemIs;
    const messageBarVM = useMessageBarVM();

    const handleSave = useCallback(() => {
        if (isAddIs || isEditIs) {
            if (!currentEditTariffItemIs.shiftCode) {
                messageBarVM.showError("Shift Code is mandatory.");
                return;
            }
            if (currentEditTariffItemIs.percentage === null) {
                messageBarVM.showError("Surcharge % is mandatory.");
                return;
            }
            if (currentTariffItem.stdIsList && currentTariffItem.stdIsList.length > 0 && currentTariffItem.stdIsList.find(entity => entity.shiftCode === currentEditTariffItemIs.shiftCode && (entity.id !== currentEditTariffItemIs.id || entity.tempKey !== currentEditTariffItemIs.tempKey))) {
                messageBarVM.showError("Duplicate record found, please check the key: Shift Code.");
                return;
            }
        }
        if (isAddTier || isEditTier) {
            if (currentEditTariffItemTier.minimum === null) {
                messageBarVM.showError("MINIMUM RATE is mandatory.");
                return;
            }
            if (currentEditTariffItemTier.rate === null) {
                messageBarVM.showError("RATE is mandatory.");
                return;
            }
            if (!currentEditTariffItemTier.uom) {
                messageBarVM.showError("UOM is mandatory.");
                return;
            }
            if (!currentEditTariffItemTier.forevery) {
                messageBarVM.showError("FOR EVERY is mandatory.");
                return;
            }
            if (!currentEditTariffItemTier.cycle) {
                messageBarVM.showError("CYCLE is mandatory.");
                return;
            }
            if (Number(currentEditTariffItemTier.minimum) > Number(currentEditTariffItemTier.rate)) {
                messageBarVM.showError("The Minimum should not be larger than Rate.");
                return;
            }
            if (isAddTier && currentTariffItem.stdIsList && currentTariffItem.stdIsList.length > 0 && currentTariffItem.tierList && currentTariffItem.tierList.length === 1) {
                messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
                return;
            }
            if (!((currentEditTariffItemTier.surcharge && currentEditTariffItemTier.surchargeType)
                || (!currentEditTariffItemTier.surcharge && !currentEditTariffItemTier.surchargeType))) {
                messageBarVM.showError("Please input SURCHARGE and SURCHARGE TYPE together.");
                return;
            }

            if (!((currentEditTariffItemTier.additionalUom && currentEditTariffItemTier.additionalRate)
                || (!currentEditTariffItemTier.additionalUom && !currentEditTariffItemTier.additionalRate))) {
                messageBarVM.showError("Please input ADDITIONAL RATE and ADDITIONAL UOM together.");
                return;
            }
        }
        if (isEditTier || isAddTier) {
            standardProposalVM.onTempSaveTariffItemTier(isAddTier);
            return;
        }
        if (isAddIs) {
            if (currentTariffItem.tierList && currentTariffItem.tierList.length > 1) {
                messageBarVM.showError("Intensive surcharge does not support multiple charge tier.");
                return;
            }
        }
        standardProposalVM.onTempSaveTariffItemIs(isAddIs);
    }, [currentEditTariffItemIs.id, currentEditTariffItemIs.percentage, currentEditTariffItemIs.shiftCode, currentEditTariffItemIs.tempKey, currentEditTariffItemTier.additionalRate, currentEditTariffItemTier.additionalUom, currentEditTariffItemTier.cycle, currentEditTariffItemTier.forevery, currentEditTariffItemTier.minimum, currentEditTariffItemTier.rate, currentEditTariffItemTier.surcharge, currentEditTariffItemTier.surchargeType, currentEditTariffItemTier.uom, currentTariffItem.stdIsList, currentTariffItem.tierList, isAddIs, isAddTier, isEditIs, isEditTier, messageBarVM, standardProposalVM]);

    const handleCancel = useCallback(() => {
        standardProposalVM.cancelAddOrEdit();
    }, [standardProposalVM]);

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        standardProposalVM.onTierFieldChange(fieldName, fieldValue);
    }, [standardProposalVM]);
    const onIsNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        standardProposalVM.onIsFieldChange(fieldName, fieldValue);
    }, [standardProposalVM]);

    const memoCycle = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.cycle?.toString() || ''}
                fieldValue={currentTier?.cycle?.toString()}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.CYCLE}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'cycle'}
                maxLength={60}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.CYCLE, currentTier?.cycle, standardProposalVM])

    const memoForEvery = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.forevery?.toString() || ''}
                fieldValue={currentTier?.forevery?.toString()}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.FOR_EVERY}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'forevery'}
                maxLength={60}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.FOR_EVERY, currentTier?.forevery, standardProposalVM])

    const memoUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.uom || ''}
                fieldValue={currentTier?.uom}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.UOM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'uom'}
                maxLength={60}
                options={uomDropdownOption}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.UOM, currentTier?.uom, standardProposalVM])

    const memoRate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.RATE}
                maxLength={60}
                fieldName={"rate"}
                value={currentTier?.rate ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.RATE, currentTier?.rate, onNumberFieldChange])

    const memoMinimun = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.MINIMUM}
                maxLength={60}
                fieldName={"minimum"}
                value={currentTier?.minimum ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.MINIMUM, currentTier?.minimum, onNumberFieldChange])

    const memosurCharge = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.SURCHARGE}
                maxLength={60}
                fieldName={"surcharge"}
                value={currentTier?.surcharge ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SURCHARGE, currentTier?.surcharge, onNumberFieldChange])

    const memoSurChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.surchargeType || ''}
                fieldValue={currentTier?.surchargeType}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.SURCHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'surchargeType'}
                maxLength={60}
                options={surChargeTypeDropdownOption}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SURCHARGE_TYPE, currentTier?.surchargeType, standardProposalVM])

    const memoAdditionalRate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_RATE}
                maxLength={60}
                fieldName={"additionalRate"}
                value={currentTier?.additionalRate ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                optional={true}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_RATE, currentTier?.additionalRate, onNumberFieldChange])

    const memoAdditionalUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentTier?.additionalUom || ''}
                fieldValue={currentTier?.additionalUom}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_UOM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'additionalUom'}
                maxLength={60}
                options={uomDropdownOption}
                requiredFieldList={standardProposalTierRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onTierFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.ADDITIONAL_UOM, currentTier?.additionalUom, standardProposalVM])

    const memoShiftCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentIs?.shiftCode || ''}
                fieldValue={currentIs?.shiftCode}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.SHIFT_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shiftCode'}
                maxLength={60}
                options={standardProposalState.dynamicOptions.shiftCodeDropdownOptions}
                requiredFieldList={standardProposalIsRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalVM.onIsFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SHIFT_CODE, currentIs?.shiftCode, standardProposalState.dynamicOptions.shiftCodeDropdownOptions, standardProposalVM])

    const memoPercentage = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "190px", marginBottom: "10px" }}>
            <NumberInputComponent
                label={STANDARD_PROPOSAL_CONSTANT.SURCHARGE_P}
                maxLength={60}
                fieldName={"percentage"}
                value={currentIs?.percentage ?? ''}
                errorMessage={''}
                disabled={false}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onIsNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.SURCHARGE_P, currentIs?.percentage, onIsNumberFieldChange])



    return <div className="side-form-content-wrapper" >

        <div className={'flex-row'}>
            <div className={'flex-row-item flex-row-item-full-width'}>
                <Sidebarheader>
                    <SidebarTitle>
                        {isAddTier && "Add " + STANDARD_PROPOSAL_CONSTANT.TIER_VALUE}
                        {isEditTier && "Update " + STANDARD_PROPOSAL_CONSTANT.TIER_VALUE}
                        {isAddIs && "Add " + STANDARD_PROPOSAL_CONSTANT.IS_VALUE}
                        {isEditIs && "Update " + STANDARD_PROPOSAL_CONSTANT.IS_VALUE}
                    </SidebarTitle>
                    <SidebarActionCross>
                        <IconButton
                            fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={handleCancel} />
                        <IconButton
                            fileName="Icon-tick" size="medium" toolTipText='Save' toolTipArrow={false} onClick={handleSave} />
                    </SidebarActionCross>
                </Sidebarheader>
            </div>
        </div>

        <div className={'add-edit-form'} style={{ height: '30vh', maxHeight: '30vh', overflow: 'visible', zIndex: '100' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    {(isAddTier || isEditTier) &&
                        <>
                            <CriteriaItemContainer>
                                {memoCycle}
                                {memoForEvery}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoRate}
                                {memoMinimun}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoUom}
                                {memosurCharge}
                            </CriteriaItemContainer>

                            <CriteriaItemContainer>
                                {memoAdditionalRate}
                                {memoSurChargeType}
                            </CriteriaItemContainer>
                            <CriteriaItemContainer>
                                {memoAdditionalUom}
                            </CriteriaItemContainer>
                        </>}
                    {(isAddIs || isEditIs) &&
                        <>
                            <CriteriaItemContainer>
                                {memoShiftCode}
                                {memoPercentage}
                            </CriteriaItemContainer>
                        </>}
                </div>
            </div>
        </div>
    </div>;
}

export default memo(TariffItemIsEditPanel);