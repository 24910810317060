import { EMPTY_STANDARD_PROPOSAL_ENTITY, StandardProposalEntity } from "domain/entity/StandardProposal/StandardProposalEntity";
import { EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY, StandardProposalItemEntity } from "domain/entity/StandardProposal/StandardProposalItemEntity";
import { EMPTY_STD_PROPOSAL_IS_ENTITY, StdProposalIsEntity } from "domain/entity/StandardProposal/StdProposalIsEntity";
import { EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY, StdProposalTariffItemTierEntity } from "domain/entity/StandardProposal/StdProposalTariffItemTierEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface StandardProposalDetailDropdownOptions {
    companyCodeDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: DropdownProps[],
    forwarderCodeDropdownOptions: DropdownProps[],
    currencyCodeDropdownOptions: DropdownProps[],
    tariffNatureDropdownOptions: DropdownProps[],
    proposalByDropdownOptions: DropdownProps[],
    shiftCodeDropdownOptions: DropdownProps[],
}
export interface StandardProposalDetailChangeState extends BaseViewChangeSate {
}

export interface StandardProposalDetailModel {
    isLoading: boolean,
    dynamicOptions: StandardProposalDetailDropdownOptions,
    detailState: StandardProposalDetailChangeState,
    currentStandardProposal: StandardProposalEntity,
    standardProposalItemList: StandardProposalItemEntity[],
    currentSelectItem: StandardProposalItemEntity,
    currentSelectItemRows: StandardProposalItemEntity[],
    isShowAddTariffItemPanel: boolean,
    isAddTier: boolean,
    isAddIs: boolean,
    isEditTier: boolean,
    isEditIs: boolean,
    isAddTariffItem: boolean,
    currentTariffItemTier: StdProposalTariffItemTierEntity,
    tariffItemTierSelectedRows: StdProposalTariffItemTierEntity[],
    currentTariffItemIs: StdProposalIsEntity,
    tariffItemIsSelectedRows: StdProposalIsEntity[],
    initTariffCode: boolean,
    tariffCodeIds: string[],
    currentEditTariffItemTier: StdProposalTariffItemTierEntity,
    currentEditTariffItemIs: StdProposalIsEntity,
    tariffNatureMap: { [key: string]: string[] },
    allFormState: { [key: string]: string },
}

export const EMPTY_STANDARD_PROPOSAL_DETAIL_MODEL: StandardProposalDetailModel = {
    isLoading: false,
    currentStandardProposal: { ...EMPTY_STANDARD_PROPOSAL_ENTITY },
    detailState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    },
    dynamicOptions: {
        companyCodeDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: [],
        forwarderCodeDropdownOptions: [],
        currencyCodeDropdownOptions: [],
        tariffNatureDropdownOptions: [],
        proposalByDropdownOptions: [],
        shiftCodeDropdownOptions: [],
    },
    standardProposalItemList: [],
    currentSelectItem: { ...EMPTY_STANDARD_PROPOSAL_ITEM_ENTITY },
    isShowAddTariffItemPanel: false,
    isAddTariffItem: false,
    isAddTier: false,
    isAddIs: false,
    isEditTier: false,
    isEditIs: false,
    initTariffCode: false,
    currentTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
    currentTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY },
    tariffCodeIds: [],
    currentSelectItemRows: [],
    tariffItemTierSelectedRows: [],
    tariffItemIsSelectedRows: [],
    currentEditTariffItemTier: { ...EMPTY_STD_PROPOSAL_TARIFF_ITEM_TIER_ENTITY },
    currentEditTariffItemIs: { ...EMPTY_STD_PROPOSAL_IS_ENTITY },
    tariffNatureMap: {},
    allFormState: {},
}