import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useStandardProposalVM } from "presentation/hook/StandardProposal/useStandardProposalVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalTracked } from "presentation/store/StandardProposal/StandardProposalProvider";
import { memo, useCallback, useMemo } from "react";
import { HPHButton, SliderPanel } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CopyStdProposalTablePanel from "./CopyStdProposalTablePanel";
import UpdatePercentageAndProposalNoPanel from "./UpdatePercentageAndProposalNoPanel";

const TariffItemCopyRightPanel = () => {
    const [standardProposalState] = useStandardProposalTracked();
    const standardProposalVM = useStandardProposalVM();
    const messageBarVM = useMessageBarVM();
    const { tariffItemSelectedRows, currentSelectedRow, copyToNewProposalList, isCopyToNewProposal, copyToCompanyList,
        copyToPercentageList, copyToProposalList, isEditPercentage, isEditProposalNo, isEditNewProposalNo, searchCriteria, searchStatus } = standardProposalState;


    const memoCopyStdProposalTablePanel = useMemo(() => {
        return <CopyStdProposalTablePanel />
    }, [])

    const memoUpdatePercentageAndProposalNoPanel = useMemo(() => {
        return <UpdatePercentageAndProposalNoPanel />
    }, [])



    const isCopyDisable = () => {
        if (isEditPercentage || isEditProposalNo || isEditNewProposalNo) return true
        return false
    }

    const checkCopy = useCallback(() => {
        return copyToCompanyList.length > 0 && copyToPercentageList && copyToPercentageList.every(entity =>
            entity.tariffType !== null && entity.tariffType !== undefined && entity.tariffType.trim() !== '' &&
            entity.chargePercentage !== null && entity.chargePercentage !== undefined &&
            entity.roundMethod !== null && entity.roundMethod !== undefined && entity.roundMethod.trim() !== '' &&
            entity.decimalPlace !== null && entity.decimalPlace !== undefined && entity.decimalPlace.trim() !== ''
        ) && copyToProposalList && copyToProposalList.every(entity =>
            entity.companyCode !== null && entity.companyCode !== undefined && entity.companyCode.trim() !== '' &&
            entity.proposalNo !== null && entity.proposalNo !== undefined && entity.proposalNo.trim() !== ''
        );
    }, [copyToCompanyList, copyToPercentageList, copyToProposalList]);

    const handleCopy = useCallback(() => {
        if (!isCopyToNewProposal && !checkCopy()) {
            messageBarVM.showError("Please input the % change or select copy to company code's proposal no.");
            return;
        }
        if (isCopyToNewProposal && copyToNewProposalList.length < 1) {
            messageBarVM.showError("Please select copy to company code(s).");
            return;
        }
        if (isCopyToNewProposal && copyToNewProposalList && !copyToNewProposalList.every(entity =>
            entity.effectiveDate !== null && entity.expiryDate !== null &&
            entity.proposalCreatedByMethod !== null && entity.currencyCode !== null
        )) {
            messageBarVM.showError("Please input the copy to new proposal's Effective Date, Expiry Date, Proposal By, Currency Code.");
            return;
        }
        standardProposalVM.onShowLoading();
        if (!isCopyToNewProposal) {
            standardProposalVM.onTariffItemCombineCopy(tariffItemSelectedRows, copyToPercentageList, copyToProposalList, currentSelectedRow).then(async (data) => {
                if (data.code !== "200") {
                    messageBarVM.showError(data.data);
                    standardProposalVM.onHideLoading();
                    return;
                } else {
                    if (searchStatus !== "") {
                        await standardProposalVM.onGroupButtonClick(searchStatus);
                    } else {
                        await standardProposalVM.searchStandardProposal(searchCriteria);
                    }
                    standardProposalVM.onHideLoading();
                }
            });
        } else {
            standardProposalVM.onTariffItemNewCopy(tariffItemSelectedRows, copyToPercentageList, copyToNewProposalList, currentSelectedRow).then(async (data) => {
                if (data.code !== "200") {
                    messageBarVM.showError(data.data);
                    standardProposalVM.onHideLoading();
                    return;
                } else {
                    if (searchStatus !== "") {
                        await standardProposalVM.onGroupButtonClick(searchStatus);
                    } else {
                        await standardProposalVM.searchStandardProposal(searchCriteria);
                    }
                    standardProposalVM.onHideLoading();
                }
            });
        }
    }, [checkCopy, standardProposalVM, isCopyToNewProposal, messageBarVM, tariffItemSelectedRows, copyToPercentageList, copyToProposalList, currentSelectedRow, searchStatus, searchCriteria, copyToNewProposalList]);


    return <>
        <div className={`main-comp-wrapper${(isEditPercentage || isEditProposalNo || isEditNewProposalNo) ? '' : ' im-hide-side-form-draggable'}`}>
            <div style={{ width: '100%', height: '100%', maxHeight: '85%' }}>
                <SliderPanel
                    isOpen={true}
                    draggable={true}
                    leftSectionWidth={(isEditPercentage || isEditProposalNo || isEditNewProposalNo) ? "40%" : "100%"}
                    rightSectionWidth={(isEditPercentage || isEditProposalNo || isEditNewProposalNo) ? "60%" : "0%"}
                    leftChildren={memoCopyStdProposalTablePanel}
                    rightChildren={memoUpdatePercentageAndProposalNoPanel}
                />
            </div>

            <div style={{ paddingRight: '20px', visibility: isCopyDisable() ? 'hidden' : 'visible', height: '100%', maxHeight: '15%', marginTop: '5px' }} >
                <SidebarActionBar>
                    {<HPHButton disabled={isCopyDisable()} label={WorkspaceConstant.Common.BUTTON_CONFIRM} size={'Small'} theme={'Primary'} onClick={handleCopy} />}
                </SidebarActionBar>
            </div>

        </div>
    </>
}

export default memo(TariffItemCopyRightPanel);