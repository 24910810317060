import { proposalByDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { StandardProposalConstant, standardProposalRequiredFieldList } from "presentation/constant/StandardProposal/StandardProposalConstant";
import { useStandardProposalDetailVM } from "presentation/hook/StandardProposal/useStandardProposalDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useStandardProposalDetailTracked } from "presentation/store/StandardProposal/StandardProposalDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NbisInputDropDown from "presentation/view/components/NbisInputDropDown";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const StandardProposalHeaderFormPanel = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [standardProposalDtlState] = useStandardProposalDetailTracked();
    const standardProposalDtlVM = useStandardProposalDetailVM();
    const { currentStandardProposal, standardProposalItemList, isShowAddTariffItemPanel } = standardProposalDtlState;
    const STANDARD_PROPOSAL_CONSTANT = StandardProposalConstant.Table;
    const messageBarVM = useMessageBarVM();

    useEffect(() => {
        if (standardProposalDtlState.allFormState && standardProposalDtlState.allFormState["updateNewTariffNatureFail"]) {
            messageBarVM.showError(standardProposalDtlState.allFormState["updateNewTariffNatureFail"]?.toString());
        }
    }, [messageBarVM, standardProposalDtlState.allFormState])

    const handleSave = useCallback(async () => {
        if (!standardProposalDtlState.currentStandardProposal.effectiveDate) {
            messageBarVM.showError("Effective Date is mandatory.");
            return;
        }
        if (!standardProposalDtlState.currentStandardProposal.expiryDate) {
            messageBarVM.showError("Expiry Date is mandatory.");
            return;
        }
        if (new Date(standardProposalDtlState.currentStandardProposal.expiryDate) <= new Date(standardProposalDtlState.currentStandardProposal.effectiveDate)) {
            messageBarVM.showError("The Expiry Date must be greater than the Effective Date.");
            return;
        }
        setIsLoading(true);
        standardProposalDtlVM.onSaveStdProposal(standardProposalDtlState.tariffCodeIds, standardProposalDtlState.currentStandardProposal, standardProposalDtlState.standardProposalItemList).then((data) => {
            setIsLoading(false);
            standardProposalDtlVM.onCloseClick();
        });
    }, [messageBarVM, standardProposalDtlState.currentStandardProposal, standardProposalDtlState.standardProposalItemList, standardProposalDtlState.tariffCodeIds, standardProposalDtlVM]);

    const handleReset = useCallback(async () => {
        standardProposalDtlVM.onResetClick();
    }, [standardProposalDtlVM]);

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisInputDropDown
                label={STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE}
                width='180px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={currentStandardProposal.companyCode}
                onChange={(e: any) => standardProposalDtlVM.onHeaderSingleDropdownChange(e, 'companyCode')}
                options={standardProposalDtlState.dynamicOptions.companyCodeDropdownOptions} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.COMPANY_CODE, currentStandardProposal?.companyCode, standardProposalDtlState.dynamicOptions.companyCodeDropdownOptions, standardProposalDtlVM])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisInputDropDown
                label={STANDARD_PROPOSAL_CONSTANT.OPERATING_TML}
                width='180px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={currentStandardProposal.operatingTml}
                optional={true}
                onChange={(e: any) => standardProposalDtlVM.onHeaderSingleDropdownChange(e, 'operatingTml')}
                options={standardProposalDtlState.dynamicOptions.operatingTmlDropdownOptions} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.OPERATING_TML, currentStandardProposal?.operatingTml, standardProposalDtlState.dynamicOptions.operatingTmlDropdownOptions, standardProposalDtlVM])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={false}
                label={STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE}
                width="180px"
                date={currentStandardProposal?.effectiveDate}
                fieldName="effectiveDate"
                errorMessage={""}
                onDateChange={standardProposalDtlVM.onHeaderFieldChange} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.EFFECTIVE_DATE, currentStandardProposal?.effectiveDate, standardProposalDtlVM.onHeaderFieldChange])

    const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <DatePickerComponent
                disabled={false}
                label={STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE}
                width="180px"
                date={currentStandardProposal?.expiryDate}
                fieldName="expiryDate"
                errorMessage={""}
                onDateChange={standardProposalDtlVM.onHeaderFieldChange} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.EXPIRY_DATE, currentStandardProposal?.expiryDate, standardProposalDtlVM.onHeaderFieldChange])

    const memoCurrency = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisInputDropDown
                label={STANDARD_PROPOSAL_CONSTANT.CURRENCY}
                width='180px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={currentStandardProposal.currencyCode}
                onChange={(e: any) => standardProposalDtlVM.onHeaderSingleDropdownChange(e, 'currencyCode')}
                options={standardProposalDtlState.dynamicOptions.currencyCodeDropdownOptions} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.CURRENCY, currentStandardProposal?.currencyCode, standardProposalDtlState.dynamicOptions.currencyCodeDropdownOptions, standardProposalDtlVM])

    const memoProposalBy = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisInputDropDown
                label={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY}
                width='180px'
                inputType="freeText"
                mode={'single'}
                sort={false}
                value={currentStandardProposal.proposalCreatedByMethod}
                onChange={(e: any) => standardProposalDtlVM.onHeaderSingleDropdownChange(e, 'proposalCreatedByMethod')}
                options={proposalByDropdownOption} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_BY, currentStandardProposal?.proposalCreatedByMethod, standardProposalDtlVM])

    const memoStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentStandardProposal?.status || 'NEW'}
                fieldValue={currentStandardProposal?.status}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.STATUS}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'status'}
                maxLength={60}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.STATUS, currentStandardProposal?.status, standardProposalDtlVM])

    const memoProposalNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentStandardProposal?.proposalNo || ''}
                fieldValue={currentStandardProposal?.proposalNo}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'proposalNo'}
                maxLength={60}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.PROPOSAL_NO, currentStandardProposal?.proposalNo, standardProposalDtlVM])

    const memoNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentStandardProposal.tariffNatureList?.join(',')}
                fieldValue={currentStandardProposal?.tariffNatureList}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.TARIFF_NATURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffNatureList'}
                maxLength={60}
                allValues={currentStandardProposal?.tariffNatureList?.join(",")}
                isMultipleDropDown={true}
                options={standardProposalDtlState.dynamicOptions.tariffNatureDropdownOptions}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTariffNatureFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />


            {/* <NbisInputDropDown
                label={STANDARD_PROPOSAL_CONSTANT.TARIFF_NATURE}
                width='395px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={currentStandardProposal.tariffNatureList?.map((item) => ({
                    value: item
                }))}
                onChange={(e:any) => standardProposalDtlVM.onHeaderMultipleDropdownChange(e, 'tariffNatureList')}
                options={standardProposalDtlState.dynamicOptions.tariffNatureDropdownOptions}/> */}
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.TARIFF_NATURE, currentStandardProposal?.tariffNatureList, standardProposalDtlState.dynamicOptions.tariffNatureDropdownOptions, standardProposalDtlVM])

    const memoRejectReason = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "24px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentStandardProposal?.rejectReason || ''}
                fieldValue={currentStandardProposal?.rejectReason}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.REJECT_REASON}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'rejectReason'}
                maxLength={200}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.REJECT_REASON, currentStandardProposal?.rejectReason, standardProposalDtlVM])

    const memoRemarks = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "24px" }} >
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={false}
                isShowOptional={true}
                readOnlyValue={currentStandardProposal?.remarks || ''}
                fieldValue={currentStandardProposal?.remarks}
                fieldLabel={STANDARD_PROPOSAL_CONSTANT.REMARKS}
                isSaveClicked={false}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'remarks'}
                maxLength={200}
                requiredFieldList={standardProposalRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    standardProposalDtlVM.onTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [STANDARD_PROPOSAL_CONSTANT.REMARKS, currentStandardProposal?.remarks, standardProposalDtlVM])


    const isSaveDisable = () => {
        if (!isShowAddTariffItemPanel && standardProposalItemList.length > 0) return false
        return true;
    }

    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
                        <Sidebarheader style={{ width: '100%' }}>
                            <SidebarTitle>{currentStandardProposal.proposalNo ?? "HEADER"}</SidebarTitle>
                            <SidebarActionCross>
                                <>
                                    {/* {isRead && <IconButton fileName='Icon-pen' size='medium' disabled={!isRead} toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />} */}
                                    <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />
                                    <IconButton fileName="Icon-tick" size="medium" disabled={isSaveDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                                </>
                            </SidebarActionCross>

                        </Sidebarheader>

                    </StyledSidebar>
                </div>
            </div>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <div className={'add-edit-form'} style={{ height: '78vh', maxHeight: '78vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>

                        <CriteriaItemContainer>
                            {memoProposalNo}
                            {memoStatus}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCompanyCode}
                            {memoProposalBy}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoCurrency}
                            {memoOperatingTml}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoEffectiveDate}{memoExpiryDate}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoNature}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRejectReason}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoRemarks}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

        </div>
    </>
}
